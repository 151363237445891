import { Paginated } from '@feathersjs/feathers';
import { ProgramUserRole, Role } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { DatafiProAPI } from 'services/api/DatafiProAPI';
import { AlertService } from 'services/util/Alert';
import { Program } from 'types/Program';
import { ValidatedRole } from 'types/Roles';
import { ValidatedUser } from 'types/Users';

export interface ProgramUser {
    userRoleId?: number;
    programTitle: string;
    programId: number;
    roleTitle?: string;
    roleId: number;
    action: 'create' | 'update' | 'remove';
}

@autoinject
export class AdminEditUserPrograms {
    @bindable user: ValidatedUser;
    @bindable programUser: ProgramUser[];
    programs: Program[];
    roles: ValidatedRole[];

    constructor(
        private api: DatafiProAPI,
        private alerts: AlertService,
    ) {}

    async attached(): Promise<void> {
        this.roles = await this.api.roles.find({ $limit: 50, query: { scopes: ['program'] } })
            .then((result: Paginated<Role>) => result.data);

        await this.fetchAllPrograms().then((programs: Program[]) => {
            this.programs = programs;
        });

        if (!this.user.id) return;

        this.api.programUsersRoles.find({
            query: {
                user_id: this.user.id,
                $limit: 50,
            },
        }).then((programUserRolesResult: Paginated<ProgramUserRole>) => {
            if (programUserRolesResult?.data.length) {
                return this.programUserList(programUserRolesResult.data);
            }
        });
    }

    async fetchAllPrograms(skip = 0, allPrograms = []): Promise<Program[]> {
        const limit = 25;
        const result = await this.api.programs.find({
            query: {
                $select: ['id', 'title'],
                $eager: 'roles.role',
                $limit: limit,
                $skip: skip,
            },
        }) as Paginated<Program>;

        allPrograms = allPrograms.concat(result.data);
        if (skip + limit < result.total) {
            return this.fetchAllPrograms(skip + limit, allPrograms);
        }

        return allPrograms;
    }

    programUserList(roles: ProgramUserRole[]): void {
        roles.forEach((d) => {
            if (d.user_id !== this.user.id) return;
            const program = this.programs.find((p: Program) => p.id === d.program_id);
            const programRole = program?.roles.find((r) => r.user_id === d.user_id);

            if (programRole) {
                this.programUser.push({
                    userRoleId: d.id,
                    programTitle: program.title,
                    programId: program.id,
                    roleTitle: programRole.role.role_name,
                    roleId: programRole.role.id,
                    action: undefined,
                });
            }
        });
    }

    addProgram(id: number): void {
        const program = this.programs.find((p) => p.id === id);
        if (!program) return;

        const programUser = this.programUser.find((p) => p.programId === id) as ProgramUser;
        if (programUser && programUser.action !== 'remove') {
            this.alerts.create({
                label: 'Program already assigned',
                level: 'warning',
                dismissable: true,
            });
        }

        if (programUser?.userRoleId) {
            const existingUser = this.programUser.find((user) => user.userRoleId === programUser.userRoleId);
            existingUser.action = undefined;
        } else {
            this.programUser.push({
                programTitle: program.title,
                roleId: this.roles[0].id,
                programId: program.id,
                action: 'create',
            });
        }
    }

    removeProgram(programId: number, event?: number) {
        if (event === 0) return;

        this.programUser = this.programUser.map((p) => {
            if (p.programId === programId) {
                p.action = 'remove';
            }
            return p;
        });
    }

    updateRole(roleId: number, programId: number) {
        this.programUser.map((p) => {
            if (p.programId === programId) {
                const role = this.roles.find((r) => r.id === roleId);
                if (role) {
                    p.roleId = role.id;
                    p.roleTitle = role.role_name;
                    p.action = p.action || 'update';
                }
            }
        });
    }
}
